$(() => {
  let screen_height = $(window).height();
  let sidebar_header_height = $('.brand-text').height() + 24 + 40;
  let sidebar_footer_height = 0;
  if ($(window).width() <= 992) {
    sidebar_footer_height = $('.sidebar-footer').height();
  } else {
    sidebar_footer_height = $('.sidebar-logout').height() + 48;
  }
  $('.sidebar-menu').css('max-height', screen_height - (sidebar_footer_height + sidebar_header_height));
  $(window).on('resize', () => {
    screen_height = $(window).height();
    sidebar_header_height = $('.brand-text').height() + 24 + 40;
    if ($(window).width() <= 992) {
      sidebar_footer_height = $('.sidebar-footer').height();
    } else {
      sidebar_footer_height = $('.sidebar-logout').height() + 48;
    }
    $('.sidebar-menu').css('max-height', screen_height - (sidebar_footer_height + sidebar_header_height));
  })
  if ($('li.menu-open').length) {
      $('li.menu-open').find('.right').toggleClass('fa-angle-up fa-angle-down');
  }
  $('.nav-link').on('click', function(e) {
      $(this).find('.right').toggleClass('fa-angle-up fa-angle-down');
      $(this).find('svg.fa-calendar-days').addClass('d-none');
  });
});
