import $ from 'jquery';
import 'select2';
import 'select2/dist/css/select2.css';
$(() => {
  $('.js-basic-multiple').select2({
    closeOnSelect: false,
    placeholder: $('.js-basic-multiple').data('placeholder'),
  })
  $('.js-basic-multiple').on('select2:open', function (e) {
    $('.select2-container--default .select2-search--inline .select2-search__field').css('display', 'none');
  });
  $('.js-basic-multiple').on('select2:open', function (e) {
    var select = $(this);

    if (select.val() && select.val().length > 0) {
      $('.select2-container--default .select2-search--inline .select2-search__field').css('display', 'none');
    } else {
      $('.select2-container--default .select2-search--inline .select2-search__field').css('display', 'inline');
    }
  });
  $('.icon-select').on('click', function () {
    $(this).closest('.search-padding').find('.js-basic-multiple').select2('open');
  })
});
