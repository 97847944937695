$(() => {

	function getDataInstagram(since, until) {
		$.ajax({
			url: "/homes",
			method: "GET",
			dataType: "json",
			data: { since: since, until: until },
			success: function (response) {
				$('.follower-count').text(response.data.total_follower_count);
				$('.account-count').text(response.data.total_account_count);
				$('.message-count').text(response.data.total_message_count);
			},
			error: function (xhr) {
			},
		});
	}
	$('.btn-dashboard-day').click(function(e) {
		var since = moment().startOf('day')._d;
		var until = moment().endOf('day')._d;
		$('.dashboard-day').addClass('nav-dasboard-day');
		$('.dashboard-month').removeClass('nav-dasboard-month');
		$('.dashboard-week').removeClass('nav-dasboard-week');
		$('.nav-dasboard-date-range').removeClass('nav-dasboard-date-range-border');
		$('.vector-day').addClass('d-none');
		$('.calendar-day').removeClass('d-none');
		$('.vector-week').removeClass('d-none');
		$('.calendar-week').addClass('d-none');
		$('.vector-month').removeClass('d-none');
		$('.calendar-month').addClass('d-none');
		getDataInstagram(since, until);
	});

  $('.btn-dashboard-day').trigger('click');

	$('.btn-dashboard-week').click(function(e) {
		var since = moment().startOf('week').add(1, 'days').startOf('day')._d;
		var until = moment().endOf('week').add(1, 'days').endOf('day')._d;
		$('.dashboard-week').addClass('nav-dasboard-week');
		$('.dashboard-day').removeClass('nav-dasboard-day');
		$('.dashboard-month').removeClass('nav-dasboard-month');
		$('.nav-dasboard-date-range').removeClass('nav-dasboard-date-range-border');
		$('.vector-day').removeClass('d-none');
		$('.calendar-day').addClass('d-none');
		$('.vector-week').addClass('d-none');
		$('.calendar-week').removeClass('d-none');
		$('.vector-month').removeClass('d-none');
		$('.calendar-month').addClass('d-none');
		getDataInstagram(since, until);
	});

	$('.btn-dashboard-month').click(function(e) {
		let since = moment().startOf('month').startOf('day')._d;
		let until = moment().endOf('month').endOf('day')._d;
		$('.dashboard-month').addClass('nav-dasboard-month');
		$('.dashboard-week').removeClass('nav-dasboard-week');
		$('.dashboard-day').removeClass('nav-dasboard-day');
		$('.nav-dasboard-date-range').removeClass('nav-dasboard-date-range-border');
		$('.vector-day').removeClass('d-none');
		$('.calendar-day').addClass('d-none');
		$('.vector-week').removeClass('d-none');
		$('.calendar-week').addClass('d-none');
		$('.vector-month').addClass('d-none');
		$('.calendar-month').removeClass('d-none');
		getDataInstagram(since, until);
	});

});
